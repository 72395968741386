
.c-filters {
  margin-bottom: 35px !important;

  &__body {
    .c-filters__item {
      width: 100%;
      &:last-child {
        border-right: none;
      }
    }

    .placeholder .ss-disabled {
      color: #666 !important;
    }
  }

  &__counter {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    line-height: 30px;
    margin-right: 15px;
    font-size: 12px;
    text-align: center;
  }

  .c-filters__item {
    border-right: none;
  }

  &--secondary-mb {
    margin-bottom: 30px;
  }

  &__wrap {
    border-radius: 2px;
  }

  &__body {
    display: block;
  }

  &__mobile {
    display: none;
    font-family: 'Roboto', sans-serif;
    height: 50px;
    line-height: 50px;
    width: 100%;
    padding: 0 15px;
    position: relative;
    cursor: pointer;
    &::after {
      content: '';
      display: block;
      height: 16px;
      width: 7px;
      background: url(/img/blocks/c-filters/arrow.svg) top/cover;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      transition: all ease .3s;
      right: 15px;
      z-index: 3;
    }

    > p {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &.active {
      &::after {
        transform: rotate(180deg) translateY(50%);
        z-index: 5;
      }

    }
  }

  &__dropdown {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
    border-radius: 2px 0px 0px 2px;
    position: absolute;
    left: 0;
    right: 0;
    padding: 15px;
    line-height: 16px;
    display: none;
    z-index: 3;
    top: calc(100% + 2px);

    a {
      display: flex;
      height: 50px;
      align-items: center;

    }
    li {
      page-break-inside: avoid;
      break-inside: avoid;
      &:last-child {
        border-bottom: none;
        padding-bottom: 0;
      }
    }
  }

  &__item {
    height: 50px;
    line-height: 50px;
    width: 200px;
    padding: 0 15px;
    position: relative;
    cursor: pointer;
    transition: all ease .3s;
    &.c-filters__missing-dropdown{
      cursor: initial;
      &:after {
        display: none;
      }
    }


    p {
      width: max-content;
    }

    &.two-col {
      ul {
        column-count: 2;
        width: fit-content;
        right: 0;
        left: auto;
        li {
          width: 200px;
        }
      }
    }

    &.three-col {
      ul {
        width: fit-content;
        column-count: 3;
        left: -110px;
        li {
          width: 200px;
        }
      }
    }

    &.active {
      &:after {
        transform: rotate(180deg) translateY(50%);
      }
    }

    &:last-child {
      border-right: none;
    }
    &--primary-size {
      width: 290px;
    }

    &--secondary-size {
      width: 100%;
    }

    &::after {
      content: '';
      display: block;
      height: 16px;
      width: 7px;
      background: url(/img/blocks/c-filters/arrow.svg) top/cover;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      transition: all ease .3s;
      right: 15px;
    }

    &--primary-type {
      border: none;
      width: auto !important;
      border-right: none !important;

      &:after {
        right: 0;
      }
    }
  }
}

@media screen and (max-width: 768px) {

  .c-filters {
    margin-bottom: 30px !important;

    &__flex {
      display: flex;
      justify-content: space-between;
      transition: all ease .5s;

      .c-filters__container {
        width: 48%;
      }

      &.active {
        flex-direction: column;

        .c-filters__container {
          width: 100%;
        }
      }

    }

    &__body {
      .c-filters__item {
        border-right: none !important;
        width: 100% !important;
      }
    }

    .main-filter .c-filters__wrap {
      .c-filters__item {

        .c-filters__dropdown {
          right: 0;
          left: 0;
          column-count: auto !important;
          width: 100% !important;
          height: 300px;
          overflow: auto;

          li {
            width: 100% !important;
          }
        }

      }

    }

    &__wrap {
      flex-wrap: wrap;

    }

    &__body {
      display: none;
      &.active {
        display: block;
      }
    }

    &__container {
      margin-bottom: 10px;
    }

    &__mobile {
      display: block;
    }

    &__item {
      width: 100%;
      border-right: none;
      &:last-child {
        border-bottom: none;
      }

      &--primary-type {
        &:after {
          right: 15px;
        }
      }

    }

  }

}
