input[type="checkbox"] {
  display: none;
}
.c-check {
  display: none;

  &__wrap {
    p {
      font-weight: 300;
      font-size: 18px;
      margin-bottom: 8px;
    }

  }

  &__label {
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
    display: -webkit-box;

    a {
      text-decoration: underline;
    }
  }

  & + label::before {
    content: "";
    display: inline-block;
    height: 15px;
    min-width: 15px;
    cursor: pointer;
    flex-basis: 15px;
    margin-right: 10px;
    line-height: 15px;
    font-weight: bold;
  }

  &:checked + label::before {
    content: '✓';

  }
}
@media screen and (max-width: 1200px) {
  .c-check__label {
    margin: 0 auto;
    width: fit-content;
    display: flex;

    p{
      width: calc(100% - 25px);
    }
  }

}