.c-radio {
  display: none;

  &-circle{
    background: #fff;
    border: 1px solid #e2dede;
    min-width: 18px;
    height: 18px;
    border-radius: 100%;
    margin-right: 10px;
    transition: .3s;
    position: relative;
    display: block;


    &:before{
      content: "";
      position: absolute;
      width: 6px;
      height: 6px;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      display: block;
      border-radius: 100%;
      z-index: 1;
      transition: .3s;
    }
  }




  &__wrap {
    p {
      font-family: Roboto;
      font-weight: 300;
      font-size: 18px;
      margin-bottom: 9px;
    }
    ul {
      display: flex;
      justify-content: space-between;
      margin-bottom: 8px;
      align-items: baseline;
      flex-wrap: wrap;
    }

    li {
      display: flex;
      align-items: center;
      width: 135px;
      margin-bottom: 10px;
    }
  }

  & + label {
    font-weight: 300;
    font-size: 14px;
    position: relative;
    padding-left: 25px;

    span{
      display: block;
      margin-top: 5px;
    }
  }
}


.js-order--check-delivery > span:last-child{
  position: relative;
  right: 10px;
}

@media screen and (max-width: 1200px) {

  .c-radio__wrap ul {
    justify-content: flex-start;
  }

  .c-radio__wrap ul li {
    margin-right: 15px;
  }

  .c-check__label {
    margin: 0 auto;
    width: fit-content;
  }

}