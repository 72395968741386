//s-why-are-we

.s-why-are-we {
  //font-family: $font-secondary;
  margin-bottom: 100px;
  &__wrap {
    display: flex;
    justify-content: space-between;
    margin-bottom: 35px;
    flex-wrap: wrap;
  }
  &__item {
    width: 100%;
    max-width: 310px;
    svg {
      margin-bottom: 20px;
      height: 60px;
      width: 60px;
    }

    h3 {
      display: block;
      margin-bottom: 15px;
      font-size: 18px;
      //color: $color-text-primary;
    }

    p {
      font-weight: 300;
      font-size: 14px;
      //color: $color-text-secondary;
    }
  }

  &__btn{
    text-align: center;
    display: block;
    margin: 0 auto;
    height:50px;
    img{
      transition: all ease .3s;
      position: relative;
      left: 0;
    }
    &:hover{
      img{
        left: 15px;
      }
    }
  }
}


@media screen and (max-width: 1200px){
  .s-why-are-we{
    &__item{
      max-width: 45%;
      margin-bottom: 15px;
    }
  }

}


@media screen and (max-width: 768px){

  .s-why-are-we{
    margin-bottom: 60px;
    &__item{
      margin-bottom: 35px;
      max-width: 100%;
      p {
        font-size: 14px;

      }
    }
  }

}