.c-mobile-menu__dropdown {
  display: none;
  padding: 25px;
  position: absolute;
  width: 100%;
  background: white;
  left: 0;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
  z-index: 110;
  top: 150px;



  .c-filters {
    &__wrap {
      flex-wrap: wrap;
    }

    &__item {
      height: 60px;
      line-height: 60px;
      width: 100%;
      border-right: 0;
      background: white;
      padding: 8px 0;
    }
  }

  .c-currency {
    border: none;
  }

  .c-filters__wrap {
    margin-bottom: 40px;
    font-size: 16px;

  }

}

.c-mobile-menu {
  &__burger {

    position: relative;
    top: 50%;
    transform: translateY(-50%);
    width: 65px;
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

  }

  &__list {
    li {
      margin-bottom: 40px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

}

