.c-up-btn {
  width: 50px;
  height: 60px;
  position: relative;
  cursor: pointer;
  position: fixed;
  bottom: 15px;
  left: 15px;
  display: none;
  z-index: 10;
  svg {
    content: '';
    display: block;
    width: 16px;
    height: 31px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
  }

  &:hover {
    border: none;
    path{
      fill: white;

    }
  }
}