.quantity-block {
  display: flex;
  width: 40px;
  position: relative;
  img {
    width: 6px;
    height: 11px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

  }
  input {
    width: 20px;
    text-align: center;
  }

  button {
    position: relative;
    display: flex;
    justify-content: center;
    min-width: 15px;
    height: 23px;
    background: #EFE8E8;
  }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}
