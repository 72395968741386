//ХЛЕБНЫЕ КРОШКИ
.c-breadcrumb {

  margin-bottom: 30px;

  //ОТСТУП ВНИЗ
  &--primary-mb {
    margin-bottom: 50px;
  }

  //СТИЛИ СПИСКА
  ul {
    display: flex;
    flex-wrap: wrap;

    //ПУНКТ СПИСКА
    li {
      margin-right: 20px;
      position: relative;
      a {
        //color: $color-text-tertiary;
      }

      //СТРЕЛКИ ПУНКТА СПИСКА
      &:after {
        content: '';
        width: 5px;
        height: 10px;
        background: url(img/blocks/c-breadcrumb/arrow.svg);
        display: block;
        right: -13px;
        top: 5px;
        position: absolute;
      }
      &:last-child {
        &:after {
          display: none;
        }
      }
    }
  }
}

@media screen and (max-width: 768px){
  .c-breadcrumb{
    margin-bottom: 25px;
  }

}