//c-title

.c-title {
  text-align: center;
  margin-bottom: 45px;
  font-size: 30px;
  display: block;
  line-height: 1.2;

  &--primary-text-align {
    text-align: left;
  }

  &--primary-size {
    font-size: 30px;
  }

  &--secondary-mb {
    margin-bottom: 80px;
  }

  &--tertiary-mb {
    margin-bottom: 25px;
  }
}

@media screen and(max-width: 768px) {
  .c-title {
    font-size: 40px;
    margin-bottom: 25px;

    &--primary-size {
      font-size: 26px;
    }

    &--secondary-mb {
      margin-bottom: 45px;
    }

  }
}