//ТЕКСТ С ИКОНКОЙ
.c-text-with-icon{
  padding-left: 30px;
  position: relative;


  //ИКОНКА
  &:before{
   content: '';
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    left: 0;
  }
}