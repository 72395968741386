.s-product-info {
  margin-bottom: 60px;

  .js-extra-template {
    display: none;
  }


  &__settings {
    margin-bottom: 20px;
    display: flex;
    &-left {
      width: 272px;
      margin-right: 30px;

    }

    &-delivery {
      display: flex;
      svg {
        margin-right: 10px;
      }

    }

    &-size {
      display: flex;
      align-items: center;
      margin-bottom: 30px;
      p {
        margin-right: 10px;
      }

    }
  }

  &__order {
    margin-bottom: 40px;
    .c-button--primary-type {
      max-width: 272px;
      min-width: 272px;
      margin: 0 10px 0 0;
      color: white;
      z-index: 1;
      &.fixed {
        position: fixed;
        top: 10px;
      }
    }
    &-extra {
      margin-bottom: 15px;
      strong {
        font-size: 14px;
        line-height: 24px;
      }

      &-container {
        margin-top: 8px;
      }
    }
    &-size {

    }
  }

  &__structure {
    margin-bottom: 30px;
    strong {
      font-weight: normal;
      display: block;
      margin-bottom: 15px;
    }

    p {

    }
  }

  &__left {
    min-width: 651px;
    margin-right: 50px;
    width: 651px;
  }

  &__right {
    width: 100%;
  }

  &__wrap {
    display: flex;
  }

  &__text {
    padding: 10px 80px;
    border-radius: 2px;
    margin-bottom: 40px;
    button {
      text-decoration: underline;
      &:focus {
        outline: none;
      }
    }
  }

  .c-popup {
    &__body {
      background: white;
      padding: 30px 20px;
      border-radius: 8px;
      p {
        margin-bottom: 10px;
      }
    }

  }

  //БЫСТРЫЙ ЗАКАЗ
  &__quick {
    margin-bottom: 40px;
    border-radius: 8px;
    padding: 40px;
    position: relative;

    &-preloader {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: #fff;
      z-index: 1;
      display: none;

      .preloader-box {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }

    }

    h3 {
      font-size: 18px;
      margin-bottom: 15px;
    }

    form {
      display: flex;
      justify-content: space-between;
    }

    .c-button {
      font-size: 16px;
    }

    .c-form-control {
      min-width: 240px;
      margin-right: 10px;
    }

    .field-cartfastorder-phone {
      margin-right: 10px;
      width: 100%;
    }
  }

  //СЧЕТЧИК ЦВЕТОВ
  &__count {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .quantity-block {
      margin-left: 20px;
    }
  }

  strong {
    font-weight: normal;
    margin-right: 10px;
  }

}

@media screen and (max-width: 1200px) {
  .s-product-info {
    &__wrap {
      flex-direction: column;
    }

    &__left {
      margin: 0 auto 30px;
    }

    &__quick {
      .c-button {
        margin: 0;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .s-product-info {
    &__left {
      width: 100%;
      min-width: 100%;

    }

    &__text {
      display: none;
    }

    &__quick {

      form {
        flex-direction: column;
      }

      .field-cartfastorder-phone {
        margin-right: 0px;
      }

      .c-button {
        max-width: 100%;
      }

    }

  }

  .slider-nav {
    display: none;
  }

  .c-product-slider {
    display: block;
    width: 100%;
    &__block {
      width: 100%;
      height: 390px;
    }
  }

}

@media screen and (max-width: 576px) {
  .s-product-info {

    &__settings {
      flex-direction: column;
      &-left {
        width: 100%;
        margin-right: 0;
        margin-bottom: 30px;
      }
    }

    &__order {
      flex-direction: column;
      text-align: center;

      .c-button {
        max-width: 100%;
        margin-bottom: 15px;
      }
    }
  }
}

