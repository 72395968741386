.c-menu{
  &__item{
    margin-right: 25px;

    &:last-child{
      margin-right: 0;
    }
  }

  &__ancor{
    font-size: 14px;
    transition: all ease .3s;
  }
}