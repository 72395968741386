


.our-products {
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
  overflow: hidden;
  margin-bottom: 20px;
  position: relative;

  &__wrap{
    position: relative;
    width: 100%;
    max-width: 795px;
  }

  &__preloader{
    position: absolute;
    width: 30px;
    height: 30px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

  }

  &__slider {
    width: 100%;
    border-radius: 6px;
    overflow: hidden;
    margin-right: 25px;
    position: relative;
    opacity: 0;
    margin-bottom: 0 !important;
    height: 401px;
    & .slick-dots {
      bottom: 50px;

    }

    &.slick-initialized + .our-products__preloader{
      opacity: 0;

    }

    &-text {
      position: absolute;
      right: 90px;
      top: 50%;
      margin-left: 10px;
      transform: translateY(-50%);
    }

    &-title {
      font-weight: bold;
      font-size: 42px;
      margin-bottom: 10px;
      font-family: 'Merriweather', serif;
      display: block;
    }

    &-subtitle {
      font-family: 'Roboto', sans-serif;
      font-size: 24px;
      margin-bottom: 30px;
    }

    &-item {
      height: 401px;
      position: relative;
      overflow: hidden;
      border-radius: 6px;
    }

    &-btn {
      display: block;
      width: 80px;
      height: 40px;
      color: white;
      box-shadow: 0px 18px 35px rgba(255, 159, 188, 0.35);
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  &__cards {
    display: flex;
    flex-direction: column;
    border-radius: 6px;

    &-ancor {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
    }

    &-item {
      min-width: 350px;
      max-width: 350px;
      height: 188px;
      margin-bottom: 25px;
      border-radius: 6px;
      overflow: hidden;
      position: relative;
      &:last-child {
        margin-bottom: 0;

      }
    }

    &-btn {
      position: absolute;
      width: 172px;
      height: 38px;
      bottom: 25px;
      left: 50%;
      transform: translateX(-50%);
      border-radius: 2px;
      font-size: 14px;
      color: white;
      text-align: center;
      line-height: 38px;
      &:hover {
        color: white;
        text-decoration: none;
      }
    }
  }
}

@media screen and (max-width: 1200px) {

  .our-products {
    &__cards {
      display: none;
    }

    &__slider {
      margin-right: 0;
      width: 100%;
    }

    &__wrap{
      max-width: 100%;
    }
  }

}

@media screen and (max-width: 992px) {
  .our-products__slider-img img {
    width: auto;
  }

}

@media screen and (max-width: 768px) {
  .our-products {
    margin-bottom: 30px;
    &cards {
      display: none;
    }

    margin-top: 10px;
    &__slider {
      width: 100%;
      height: 236px;

      & .slick-dots {
        bottom: 25px;

      }

      &-title {
        margin-bottom: 5px;
        font-size: 32px;
      }
      &-subtitle {
        margin-bottom: 20px;
        font-size: 18px;
      }
      &-item {
        height: 236px;
      }

      &-text {
        top: 40px;
        right: 25px;
        transform: none;
      }

      &-btn {
        width: 60px;
        height: 30px;
      }
    }

  }
}

