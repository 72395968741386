//ПОПАП - ЛОГИН
.c-popup {
  display: none;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  margin-bottom: 5px;

  //КНОПКА ЗАКРЫТИЯ ПОПАПА
  &__close {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
  }

  //ЗАДНИЙ ФОН ПОПАПА
  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.5;
  }

  //ТЕЛО ПОПАПА
  &__body {
    position: absolute;
    z-index: 2;
    width: 90%;
    max-width: 740px;
    background: url(img/blocks/c-login/img.jpg);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 40px 0 70px;
    color: black;
    line-height: 22px;
    h4 {
      margin-bottom: 20px;
    }

    //ОБЁРТКА ДЛЯ ИНПУТА
    .c-form-control__wrap {
      position: relative;

      //ВОССТАНОВИТЬ ПАРОЛЬ
      .c-form-control__restore {
        font-family: Roboto;
        position: absolute;
        top: 15px;
        text-decoration: none;
        right: 15px;
        cursor: pointer;
      }
    }
  }

  //ФОРМА ПОПАПА
  form {
    width: 100%;
    max-width: 420px;
    margin: 0 auto;
    text-align: center;
    padding: 0 15px;
  }

  //КНОПКА "ВОЙТИ В КАБИНЕТ"
  .c-button {
    max-width: 100%;
    margin-bottom: 20px;
    color: #404040;
    &:hover {
      color: white;
    }
  }

  //ЗАРЕГИСТРИРОВАТЬСЯ
  a {
    text-decoration: underline;
  }

  &__quick {
    strong {
      display: block;
      text-align: center;
      font-size: 18px;
      line-height: 23px;
      text-align: center;
      margin-bottom: 40px;
    }

    .c-button {
      margin-top: 30px;
      margin-bottom: 0;
      color: white;
    }

  }

  &__multi-city {
    text-align: center;



    .c-popup__body {
      padding-top: 65px;
      background: white;
      min-height: 340px;
      max-width: 580px;

      span{
        font-size: 16px;
      }
    }

    .c-title {
      font-size: 24px;
      font-weight: bold;
      margin-top: 30px;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        margin-right: 10px;
      }
    }

    a {
      display: block;
      font-size: 18px;
      text-decoration: none;
      margin-bottom: 20px;
      transition: all ease .3s;
      font-weight: bold;
      opacity: 0.5;
      &:hover{
        opacity: 1;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__order{

    .c-popup__body{
      text-align: center;
      background: white;
      padding: 45px 15px;
      max-width: 660px;
    }

    .c-popup__title{
      max-width: 400px;
      margin: 0 auto 25px;
    }

    .c-form-control{
      max-width: 420px;
    }

    .c-button{
      color: white;
      max-width: 420px;
      margin-bottom: 0;
    }

    span{
      font-size: 18px;
      margin-bottom: 15px;
      display: block;
    }

    p{
      font-weight: 300;
    }

  }

}











