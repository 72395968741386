//ЛИЧНЫЙ КАБИНЕТ
.c-personal-area {
  width: calc(100% - 270px);
  border: 1px solid black;
  padding: 40px;
  box-shadow: 0px 2px 40px rgba(0, 0, 0, 0.08);
  border-radius: 8px;

  //ЗАГРОЛОВОК СТРАНИЦЫ
  h2 {
    font-size: 30px;
    margin-bottom: 60px;
    font-family: 'Merriweather', sans-serif;
  }

  //БЛОК С ФОРМОЙ
  &__form {
    padding: 30px 0;
    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
      border-bottom: none;
    }

    //КОНТЕЙНЕР БЛОКА С ФОРМОЙ
    &-wrap {
      width: 100%;
      max-width: 280px;

      //ЗАГОЛОВОК БЛОКА
      h3 {
        font-weight: 300;
        font-size: 18px;
        margin-bottom: 20px;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .c-personal-area {
    width: 100%;
  }
}