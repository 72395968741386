.c-select {
  width: 100%;

  &:last-child {
    .ss-multi-selected {
      border-right:  1px solid #dcdee2 !important;

    }
  }

  .ss-list {
    &::-webkit-scrollbar-track {
      background-color: white;
      border-radius: 10px;
    }

    &::-webkit-scrollbar {
      width: 10px;
      background-color: white;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: #dcdee2;
    }
  }

  &.ss-main .ss-multi-selected .ss-values .ss-disabled {
    color: inherit;
  }

  &__container {
    display: flex;
  }


  &:last-child {
    .ss-single-selected {
      border-right: 1px solid #dcdee2 !important;
    }

  }

  .ss-single-selected {
    height: 50px;
    padding: 0 15px;
  }

  .ss-multi-selected {
    height: 50px;
    padding: 0 25px;
  }

  .ss-option:hover {
    background: transparent !important;
    color: #666 !important;
  }

  .ss-option.ss-disabled:hover {
    color: #dedede !important;
  }

  .ss-search {
    display: none;
  }

  &.ss-main .ss-single-selected {
    border-right: 1px solid black;
    border-radius: 0px;
  }

  &.ss-main .ss-multi-selected {
    position: relative;
    overflow: hidden;
    &:before {
      content: '';
      z-index: 1;
      width: 20px;
      top: 0;
      bottom: 0;
      right: 0;
      position: absolute;

    }
    border-right: 1px solid black;
    border-radius: 0px;
  }

  &.ss-main .ss-multi-selected .ss-values {
    flex-wrap: nowrap;
    width: max-content;
  }

  &.ss-main .ss-multi-selected .ss-values .ss-value {
    width: inherit;
    background: #dcdee2;
    color: black;
  }

  &.ss-main .ss-multi-selected .ss-add {
    z-index: 1;
    position: absolute;
    left: 5px;
    top: 10px;
  }

  &.ss-main .ss-single-selected .ss-arrow span {
    content: '';
    display: block;
    height: 16px;
    width: 7px;
    background: url(/img/blocks/c-filters/arrow.svg) top/cover;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: all ease .3s;
    right: 15px;
    border: none;
    margin: 0;
  }

  &.ss-main .ss-single-selected .ss-arrow span.arrow-up {
    transform: translateY(-50%) rotate(180deg) !important;
  }

  .ss-option {
    padding: 16px 0 !important;
  }

  .ss-list {
    padding: 15px;
  }
}

@media screen and (max-width: 768px) {
  .c-select {
    width: 100%;

    &__container {
      flex-direction: column;
    }

    &--1-width {
      width: 100%;
    }
  }

  .c-select.ss-main .ss-multi-selected {
    border-right: none;
  }

}