.c-product-slider {
  margin-bottom: 25px;
  max-width: 651px;
  width: 100%;
  overflow: hidden;
  display: flex;

  &__item {
    position: relative;
  }

  &__block {
    width: 100%;
    display: block;
    height: 560px;
    max-width: 560px;
    position: relative;
  }

  &__nav-block {
    width: 80px;
    height: 80px;
    position: relative;
    margin-bottom: 3px;
  }

  .slider-nav {
    margin-right: 10px;
    min-width: 80px !important;
    max-width: 80px;
    opacity: 0;

    &.slick-initialized{
      opacity: 1;
    }
  }

  .slick-dots {
    bottom: 40px;
  }

  .slider-for {
    width: 100%;

    &.slick-slider{
      .c-product-slider__block{
        opacity: 0;
      }

    }


    &.slick-initialized{
      opacity: 1;

      .c-product-slider__block{
        opacity: 1;
      }
    }

    .slick-list {
      overflow: visible;
    }

    &.big {
      width: 651px;
      max-width: 100%;


      .c-product-slider__block {
        opacity: 1;
        width: 100%;
      }
    }
  }

}

@media screen and (max-width: 768px) {
  .c-product-slider {
    .slider-for {
      &.big {
        width: 100%;
        max-width: 651px;
      }
    }

  }

}