//s-text!


//БЛОК С ТЕКСТОМ
.s-text {
  margin-bottom: 120px;
  align-items: end;

  &__article{
    padding: 20px 0;
    border-bottom: 1px solid rgba(126, 123, 123, 0.2);
    display: flex;
    &-img{
      position: relative;
      min-width: 230px;
      height: 150px;
      margin-right: 50px;
    }

    &--1-type{
      flex-direction: column;
      padding: 0;
      max-width: 700px;
      border-bottom: 0;
      .s-text__article-img{
        height: 350px;
        margin-right: 0;
        margin-bottom: 20px;
      }

      .s-text__article-data{
        margin-bottom: 25px;
      }
    }

    &-data{
      font-size: 14px;
      margin-bottom: 10px;
    }
  }

  //НАВИГАЦИЯ
  &__nav {
    min-width: 210px;
    border-radius: 4px;
    padding: 38px;
    margin-right: 60px;
    background: white;

    //ШИРИНА НАВИГАЦИИ
    &--width-primary {
      width: 230px;
    }

    //ОТСТУП НАВИГАЦИИ
    &--mr-primary {
      margin-right: 40px;
    }


    //ПУНКТ МЕНЮ
    li {
      padding: 8px 0;
      &:last-child {
        padding-bottom: 0;
        border-bottom: none;
      }

      //ССЫЛКА ВНУТРИ ПУНКТА МЕНЮ
      a {
        font-weight: 300;
        &.active {
          font-weight: normal;
        }
      }

    }


    &-mobile{
      //КНОПКА ПОЯВЛЯЮЩАЯСЯ НА МАЛЕНЬКИХ РАЗРЕШЕНИЯХ

      &-body{
        p{
          padding-bottom: 8px;
          font-weight: 300;

        }
      }

      &-btn {
        display: none;
        cursor: pointer;
        position: relative;

        //СТРЕЛКА КНОПКИ
        &:before {
          content: '';
          width: 7px;
          height: 16px;
          background: url(img/sections/s-text/arrow.svg);
          position: absolute;
          right: 0;
          transition: all ease .3s;
          transform: rotate(180deg);
        }

        &.active {
          &:before {
            transform: rotate(0);

          }
        }

      }

      //КНОПКА ВЫХОДА
      &-exit{
        position: relative;
        padding-left: 20px !important;
        &:before{
          content: '';
          display: block;
          width: 15px;
          height: 20px;
          background: url(/img/sections/s-text/exit.svg) no-repeat;
          position: absolute;
          left: 0;
        }

      }
    }



  }
  &__content {
    width: calc(100% - 270px);
    font-size: 16px;
    line-height: 22px;
    h3 {
      font-size: 20px;
      margin-bottom: 25px;
    }

    h4 {
      margin-bottom: 25px;
      font-size: 16px;
    }

    p {
      margin-bottom: 25px;
      font-weight: 300;
    }

    li {
      font-size: 16px;
      font-weight: 300;
      padding-left: 5px;
      &:before {
        content: '-';
        position: relative;
        right: 5px;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .s-text {
    flex-direction: column;
    &__content {
      width: 100%;
      h2 {
        text-align: center;
      }
    }

    &__nav {
      &-mobile {
        &-btn {
          display: block;
        }

        &-body {
          display: none;
        }
      }
      width: 100%;
      margin-bottom: 25px;
      padding: 20px;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
      li {
        &:first-child {
          margin-top: 15px;
          padding-top: 8px;
        }
      }
    }
  }

}


@media screen and (max-width: 768px){
  .s-text__article{
    flex-direction: column;
    &-img{
      margin-right: 0;
      height: 200px;
      margin-bottom: 10px;
    }

  }
}