.s-our-advantages {
  margin-bottom: 140px;
  &__card-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}



@media (max-width: 1200px) {
  .s-our-advantages__card-list {
    justify-content: center;
  }

}


@media (max-width: 768px) {
  .s-our-advantages{
    margin-bottom: 40px;
  }
}