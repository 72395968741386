
//НАШИ ПРЕИМУЩЕСТВА - ОДИН БЛОК
.c-advantages {
  height: 300px;
  width: 325px;
  margin-bottom: 20px;
  position: relative ;
  padding: 0 25px;

  //ТЕКСТ БЛОКА
  p {
    position: relative;
    font-size: 14px;
    top: 175px;
    font-weight: 300;
    line-height: 20px;
  }

  //ПЕРВИЧНАЯ ШИРИНА БЛОКА
  &--primary-width {
    width: 490px;

  }
}

@media (max-width: 1200px) {

  .c-advantages {
    margin: 10px;
  }

}

@media (max-width: 768px) {


  .c-advantages {
    height: 220px;
    width: 100% !important;
    margin: 0 0 15px;
    p {
      top: 50%;
      transform: translateY(-50%);
    }
  }

}