
.s-card-list {
  margin-bottom: 120px;
  flex-grow: 2;
  &__wrap {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px;
  }

  &__slider {
    opacity: 0;
    margin-bottom: 40px;
    &.slick-dotted.slick-slider {
      margin-bottom: 40px;
    }
  }

  .c-settings-btn {
    min-width: 250px;
    height: 50px;
    margin-right: 25px;
    line-height: 50px;
    padding-right: 10px;
    &__wrap {
      display: flex;
      margin-bottom: 30px;
    }
    &__ic {
      width: 50px;
    }
  }
}

@media screen and (max-width: 768px) {
  .s-card-list {
    margin-bottom: 60px;

    .c-settings-btn {
      width: 100%;
      &__wrap {
        display: block;
      }
    }
  }

}