.c-form-control {
  height: 50px;
  width: 100%;
  border-radius: 4px;
  margin-bottom: 15px;
  font-size: 14px;
  padding: 15px;

  &__error {
    display: none;
    margin-bottom: 10px;
  }

  &.error {
    + .c-form-control__error {
      display: block;
    }
  }

  &--textarea {
    height: 115px;
    resize: none;
  }

  &--primary-width {
    max-width: 135px;
  }

  &__icon {
    position: absolute;
    left: 15px;
    top: 15px;
    height: 20px;

  }
}

.c-form-control__with-icon {
  position: relative;
  & .c-form-control {
    padding-left: 45px;

  }
}