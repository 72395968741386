//c-product-dropdown
.c-product-dropdown {
  &__body {
    display: none;
    overflow: hidden;
  }

  &__list {
    margin-bottom: 30px;
    h3 {
      margin-bottom: 15px;
    }

    h4 {
      font-size: 14px;
    }

    li {
      font-size: 14px;
      font-weight: 300;
    }

    p {
      font-weight: 300;
      margin-bottom: 10px;
    }

    .slick-next:before, .slick-prev:before{
      background: black;
      width: 14px;
      height: 27px;
      background: url(img/blocks/c-product-dropdown/slider-arrow.svg);
    }

  }
  &__card {
    width: 80px;
    margin-right: 10px;
    float: left;
    cursor: pointer;


    &-img{
      margin-bottom: 5px;
    }
  }

  &__title{
    cursor: pointer;
    position: relative;
    width: fit-content;

    &.active{
      &:before {
        transform: rotate(180deg);
      }
    }

    &:before{
      content: '';
      width: 10px;
      height: 5px;
      display: block;
      background: url(/img/blocks/c-product-dropdown/dropdown-arrow.svg);
      position: absolute;
      right: -25px;
      top: 50%;
      transform: translateY(-50%);
      transition: all ease .3s;

    }
  }

  &__slider {
    min-height: 125px;
    max-width: 500px;
    width: 100%;
    &.slick-slider{
      opacity: 0;
    }
    &.slick-initialized{
      opacity: 1;
    }
    p {
      margin-bottom: 0;
    }

    .slick-next{
      right: -25px;
    }

    .slick-prev{
      left: -25px;
    }
  }

}

@media screen and (max-width: 576px){
  .c-product-dropdown__slider{
    width: 100%;
    margin: 0;
  }

}