.c-order {
  align-items: flex-start;
  margin-bottom: 120px;


  .c-check__label{
    display: flex;
    margin-bottom: 15px;
  }

  &-product {
    box-sizing: border-box;
    box-shadow: 0px 2px 40px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    max-width: 420px;
    width: 100%;
    padding: 40px;
    margin-bottom: 50px;

    &__top {
      padding-bottom: 20px;
      margin-bottom: 20px;
      position: relative;
    }

    &__img {
      width: 120px;
      height: 120px;
      flex-shrink: 0;
      position: relative;
      margin-bottom: 5px;

    }

    &__text {
      flex-direction: column;
      max-width: 200px;

    }

    &__extra {
      p {
        margin-bottom: 10px;
      }

      .c-title {
        font-size: 20px;
        margin-bottom: 10px;
      }

    }

    &__delete {

      button {
        display: flex;
        align-items: center;
        position: relative;
        svg {
          margin-right: 5px;
          position: absolute;
          left: -15px;
        }
      }
    }

    .quantity-block {
      position: relative;
      right: 10px;
    }

    .quantity-block button {
      background: none;
    }

    &__more {
      cursor: pointer;
      margin-bottom: 30px;
      display: none;

      p {
        position: relative;
        display: block;
        margin: 0 auto;
        width: fit-content;
        font-size: 16px;
        &:before {
          content: '';
          display: block;
          width: 10px;
          height: 5px;
          background: url('/img/blocks/c-order-product/more.svg');
          position: absolute;
          right: -20px;
          top: 11px;
          transition: all ease .3s;
        }
      }

      &.active {
        p:before {
          transform: rotate(180deg)
        }
      }
    }

    &__info {
      padding-bottom: 5px;
      margin-bottom: 20px;
      p {
        margin-bottom: 15px;
      }
    }

    &__total {
      display: flex;
      justify-content: space-between;
      margin-bottom: 25px;
      p {
        font-weight: bold;
      }
    }

    &__promo {

      &-form {
        display: none;
        justify-content: space-between;

        .c-button {
          min-width: 140px;
          max-width: 140px;
          margin-left: 10px;
          text-decoration: none;

          &:hover{
            color: white;
          }
        }
      }

      &-success {
        display: none;
        justify-content: space-between;
        p {
          font-weight: bold;
          font-size: 14px;
        }
      }

      &-wrap {
        display: flex;
        justify-content: space-between;
      }

      button {
        text-decoration: underline;
      }
    }

    &__ex{
      line-height: 30px;
    }

  }

  &-forms {
    max-width: 700px;
    width: 100%;
    box-shadow: 0px 2px 40px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    padding: 40px 20px;
    &__payment {
      li {
        margin-right: 10px;
      }

      &-mobile {
        display: none;
      }
      &-descktop {
        display: block;
      }
    }

    &__wrap {
      align-items: end;
      .c-button {
        margin-bottom: 20px;
        color: white;
      }
    }

    &__part {
      width: calc(50% - 10px);
      padding: 20px;
    }

    &__date {
      height: 170px;
      width: 280px;
      margin: 0 auto 30px;
    }

    &__first-form {
      margin-bottom: 30px;

      .c-form-control--textarea {
        margin-bottom: 0;
      }

      .c-filters__item {
        margin-bottom: 15px;
      }

    }

    .c-select {
      width: 100%;
      margin-bottom: 15px;
      .ss-single-selected {
        border-right: none !important;
        background: white;
        border-radius: 4px !important;

      }
    }

    .select2-container--krajee .select2-selection--single {
      height: 50px;
      width: 100%;
      border-radius: 4px;
      margin-bottom: 15px;
      background: #fff;
      font-size: 14px;
      padding: 15px;
    }




  }

}

.select2-search {
  display: none !important;
}

.select2-selection__arrow{
  border: none !important;
  height: 50px !important;
}



.select2-selection{
  border: none !important;
  box-shadow: none !important;
}

.select2-results__group{
  background: white !important;
}

@media screen and (max-width: 1200px) {
  .c-order {
    flex-direction: column-reverse;

    &-product {
      max-width: 100%;
    }

    &-forms {
      max-width: 100%;

      .c-radio__wrap ul li {
        margin-right: 0;
      }
    }

  }

}

@media screen and (max-width: 768px) {

  .c-order {
    margin-bottom: 30px;
    &-forms {
      flex-direction: column;
      margin-bottom: 30px;

      &__part {
        width: 100%;

      }

      &__date {
        height: auto;
      }

      &__payment {
        margin-top: 20px;
        flex-wrap: wrap;
        justify-content: space-between;
        li {
          width: calc(100% / 3);
          height: 50px;
          line-height: 50px;
          text-align: center;
          position: relative;
          img {
            height: 20px;
          }

        }

        &-mobile {
          display: block;
        }
        &-descktop {
          display: none;
        }
      }

      &__wrap {
        flex-direction: column;
      }

      .c-filters__item {
        line-height: 50px;
        height: 50px;
      }
    }

    &-product {
      padding: 40px 20px;

      &__top {
        flex-direction: row-reverse;
      }

      &__more {
        display: block;
      }

      &__bottom {
        display: none;
      }

      &__img {
        width: 100px;
        height: 100px;
        margin-right: 10px;
      }

      &__num {
        align-items: center;
      }
    }

  }

}