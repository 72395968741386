//ВАЛЮТА
.c-currency {
  position: relative;

  //ИКОНКА ВАЛЮТЫ
  &__title {
    height: 45px;
    line-height: 45px;
    padding: 0 30px 0 15px;
    position: relative;
    cursor: pointer;
    &:before {
      content: '';
      display: block;

      width: 9px;
      height: 5px;

      background: url('/img/blocks/c-currency/arrow.svg');

      position: absolute;
      top: 50%;
      right: 14px;
      transition: translateY(-50%);
    }
  }

  //ВЫПАДАЮЩИЙ СПИСОК С ВАЛЮТАМИ
  &__dropdown {
    content: '';
    display: none;
    border-radius: 0px 0px 2px 2px;
    text-align: center;
    position: absolute;
    top: 46px;
    right: 0;
    left: 0;
    z-index: 10000;



    //ЭЛЕМЕНТ ВЫПАДАЮЩЕГО СПИСКА
    a {
      display: block;
      padding: 8px 0;
      transition: all ease .3s;
    }
  }

}


