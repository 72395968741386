
.s-footer {
  padding: 80px 0;
  flex-grow: 0;


  &__top {
    padding-bottom: 35px;

    .logo__img{
      margin-right: 20px;
      max-width: 150px;
    }
  }

  &__bottom {
    padding-top: 30px;
  }

  &__social {
    a {
      margin-right: 15px;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__nav {
    max-width: 668px;
    width: 100%;
    li {
      margin-right: 40px;
      &:last-child {
        margin-right: 0;
      }

      a{
        font-weight: 300;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .s-footer__logo {
    display: none;
  }
}

@media screen and (max-width: 992px) {
  .s-footer {
    padding: 40px 0;
    &__top {
      flex-direction: column;
      padding-bottom: 25px;
    }

    &__social {
      padding-top: 25px;
      width: 100%;

    }

    &__nav {
      flex-wrap: wrap;
      width: auto;
      font-size: 16px;
      padding-bottom: 25px;
      li {
        width: 100%;
        margin-bottom: 15px;
        &:last-child {
          margin-bottom: 0;
        }
      }

    }

    &__bottom {
      font-size: 14px;
    }
  }

}

@media screen and (max-width: 576px) {
  .s-footer__bottom {
    flex-direction: column;
    text-align: center;
  }

}



.s-footer {
  &__top {

    > svg {
      margin-right: 60px;
    }
  }

  &__nav {
    color: #404040;
  }

  &__insta {
    &-box {
      min-width: 226px;
      height: 50px;
      background: #FFFFFF;
      border: 0.5px solid #828282;
      border-radius: 2px;
      padding: 7px;
      display: flex;
      align-items: center;

      p {
        font-weight: 300 !important;
        font-size: 18px;
        color: #404040;
      }
    }

    &-ic {
      margin-right: 12px;
    }

  }

  &__mobile-social {
    display: none;
    padding-top: 25px;
    justify-content: space-around;
    width: 100%;
    align-items: center;
    border-top: 1px solid #7e7b7b;
    margin-top: 15px;

  }
}

@media screen and (max-width: 992px) {
  .s-footer {

    &__top {
      align-items: flex-start;

      > svg {
        display: none;
      }
    }

    &__bottom {
      flex-direction: column;
      align-items: center;
      p {
        margin-bottom: 15px;
      }
    }

    &__insta-box {
      width: 100%;
      justify-content: center;
    }

    &__social {
      display: none;
    }

    &__mobile-social {
      display: flex;
    }

  }

}

