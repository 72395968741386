//КОМПОНЕНТА ЛОГИНИЗАЦИИ
.c-login {
  display: flex;
  padding: 0 15px;
  cursor: pointer;

  &__icon{
    margin-right: 5px;
  }

}