.c-search {
  position: relative;
  cursor: pointer;
  z-index: 1000;
  &__title {
    width: 70px;
    text-align: center;
    cursor: pointer;
  }

  &__input {
    display: none;
    position: fixed;
    right: 0;
    z-index: 6;
    left: 0;
    top: 70px;
    &-wrap {
      position: relative;
      margin-bottom: 30px;
      display: flex;
      height: 50px;
    }

    &-ic {
      svg {
        width: 21px;
        height: 21px;
      }

      position: absolute;

      left: 15px;
      cursor: pointer;
      height: 50px;
      line-height: 50px;
      z-index: 1;
    }

  }

  .c-form-control {
    padding-left: 45px;
    margin-bottom: 0;
    position: absolute;
    top: 0;
  }

  &__shadow {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: black;
    opacity: 0.5;
    z-index: 5;
  }

}

@media screen and (max-width: 1200px) {
  .c-search__input {
    top: 20px;
  }
}