.order-info{
  margin-bottom: 50px;


  &__wrapp{
    padding: 40px;
    background: white;
    box-shadow: 0 2px 40px rgba(0,0,0,.08);
    border-radius: 8px;
    max-width: 650px;
    margin: 0 auto;
  }
  .title{
    text-align: center;
    font-size: 30px;
    margin-bottom: 45px;
  }

  li{
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    border-bottom: 1px dotted black;
  }
}