//БАЗОВЫЕ СТИЛИ ПРОСТЫХ ТЕГОВ
* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  box-sizing: border-box;
}

label{
  cursor: pointer;
}

html, body {
  margin: 0;
  padding: 0;
  line-height: 1.25;
  font-size: 14px;
}

body {

  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: 'Merriweather', serif;
}

ul, li, label {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

p, h1, h2, h3, h4 {
  margin: 0;
  font-weight: normal;
}

a {
  text-decoration: none;
  outline: none;
  color: #404040;
  &:hover {
    text-decoration: none;
    outline: none;
    color: #404040;
  }
}

input, textarea {
  background-color: rgba(255, 255, 255, 0);
  outline: none;
  text-decoration: none;
  border: none;
}

img {
  max-width: 100%;
}

:focus {
  outline: none;
}

button {
  background: transparent;
  border: none;
  display: block;
}

//КОНТЕЙНЕР
@media (min-width: 1200px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1200px;
  }
}

//SLICK СЛАЙДЕР
.slick-dots li {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  & button {
    width: 15px;
    height: 15px;

    &:before {
      display: none;
    }
  }


}

.slick-prev, .slick-next{
  z-index: 1;

}

.slick-prev:before, .slick-next:before {
  content: '';
  width: 25px;
  height: 50px;
  background: url('/img/settings/slick-arrow.svg');
  display: block;
  position: relative;
}

.slick-prev:before {
  transform: rotate(180deg);

}

.content-wrapper {
  flex-grow: 1;
}

.slick-slider {
  opacity: 0;
  visibility: hidden;
  transition: opacity 1s ease;
  -webkit-transition: opacity 1s ease;
}

.slick-slider.slick-initialized {
  visibility: visible;
  opacity: 1;
}

.js-confirmation--timer {

}

.form-group {
  margin-bottom: 0;
}

.js-confirmation--timer {
  margin-bottom: 15px;
}

.help-block {
  position: relative;
  bottom: 8px;
}

.the-content {
  position: relative;
  &__text {
    width: calc(100% - 30px);
    text-align: center;
    max-width: 600px;
    background: white;
    border-radius: 8px;
    padding: 20px;
    margin: 0 auto 50px;

    border: 1px solid #efe8e8;
    box-shadow: 0 2px 40px rgba(0, 0, 0, .08);

    .title {
      margin-bottom: 20px;
    }
  }
}

.c-discount__text {
  margin-bottom: 15px;
}

.delivery-bonus {
  display: flex;
  flex-direction: column;
  &__old {
    margin: 10px 0;
    font-size: 12px;
    text-decoration: line-through;

  }
}


.c-discount {
  .checkbox__nesting:before {
    content: "";
    display: inline-block;
    height: 15px;
    width: 15px;
    background: #fff;
    border: 1px solid #000;
    cursor: pointer;
    -ms-flex-preferred-size: 15px;
    flex-basis: 15px;
    margin-right: 10px;
    color: #fff;
    line-height: 15px;
    font-weight: 700;
  }

  .checkbox__nesting{
    display: flex;
  }

  .c-check:checked + .checkbox__nesting:before {
    content: '✓';
  }
}

.select2-container--krajee .select2-dropdown{
  border: none;
}

.select2-container--krajee .select2-results__option--highlighted[aria-selected], .select2-container--krajee .select2-results__option[aria-selected=true]{
  background: white !important;
  color: black !important;
}

.select2-container--krajee .select2-results__group{
  border-bottom: none !important;
}

.select2-container--krajee.select2-container--open .select2-selection--single .select2-selection__arrow b{
  display: none;

}

.select2-container--krajee .select2-selection--single .select2-selection__arrow{
  display: none;

}

.select2:before{
  content: '';
  display: block;
  height: 16px;
  width: 7px;
  background: url(/img/blocks/c-filters/arrow.svg) top/cover;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  transition: all ease .3s;
  right: 15px;
  border: none;
  margin: 0;
}



.select2.select2-container.select2-container--krajee.select2-container--below.select2-container--focus.select2-container--open:before{
  transform: rotate(180deg) translateY(50%);

}




.select2-results__option{
  padding: 16px 0!important;
  border-bottom: .5px solid #efe8e8;
}

.select2-results__option{
  padding: 15px !important;
}

.select2-container--krajee li.select2-results__option{
  background: white !important;
}

.select2-container--krajee .select2-results__group{
  border-top: none !important;
}


//ПАГИНАЦИЯ

.pagination{
  padding: 3px;
  display: inline-flex;
  border-radius: 0;
  .prev{
    display: none;
  }

  .next{
    display: none;
  }

  li {
    position: relative;
    width: 33px;
    height: 33px;
    a{
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      font-size: 16px;
      font-family: 'Roboto', sans-serif;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all ease .3s;
    }
  }
}



