.s-not-found{
  margin: 70px 0;
  &__img{
    margin: 0 auto;
    position: relative;
    max-width: 380px;
    height: 300px;
    img{
      object-fit: contain;
    }
  }
}