.s-florist-form {
  margin-bottom: 120px;

  .c-select{
    width: 100%;
    font-family: 'Merriweather', serif;
  }

  .c-select.ss-main .ss-multi-selected{
    border-right: 1px solid #efe8e8;
    background: white;
    margin-bottom: 15px;
  }

  .c-select.ss-main .ss-multi-selected:before{
    background: white;
  }

  .c-select .ss-single-selected{
    background: white;
    border-right: 1px solid #efe8e8;
  }

  .c-check__label{
    display: flex;
    margin-bottom: 15px;
  }






  .js-time{
    display: flex;
    position: relative;
    width: 280px;
    justify-content: space-between;
  }

  &__body {
    border-radius: 8px;
    position: relative;
    padding: 65px 50px 30px;

    img{
      object-fit: contain;
      object-position: left;
    }
  }

  &__small-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  &__wrap {
    position: relative;
    z-index: 1;
  }

  &__sub {
    width: 100%;
    max-width: 390px;
    margin: 0 auto;
    line-height: 21px;
  }

  &__title {
    margin-bottom: 45px;
  }

  &__col {
    width: 280px;
   .c-radio__wrap{
     height: 50px;
     margin-bottom: 15px;
   }

    .currency-block{
      position: relative;

      &__ic{
        position: absolute;
        right: 10px;
        top: 15px;
        svg{
          width: 12px !important;
        }
      }
    }

  }

  &__main {
    width: 960px;
    margin: 0 auto;
  }

  .c-form-control--textarea {
    margin-bottom: 0;
  }

  .c-button {
    margin-bottom: 15px;
  }

  .c-filters__item {
    margin-bottom: 15px;
  }

}



@media screen and (max-width: 1200px) {

  .s-florist-form {
    &__main {
      width: 100%;
      display: block;
    }

    &__col {
      width: 100%;
      margin-bottom: 20px;
    }

    &__body {
      background: linear-gradient(89.93deg, #F1F0EF 9.72%, #F6F5F4 97.72%);
      padding: 40px 35px;

      img{
        object-position: top ;
      }

    }

    &__small-container {
      justify-content: flex-start;
    }


  }
}


@media screen and (max-width: 768px) {
  .s-florist-form{
    margin-bottom: 60px;
    &__bg{
      height: auto;
    }
    .c-filters__item {
      height: 50px;
      line-height: 50px;
    }

    .c-radio__wrap{
      min-height: 50px;
      height: auto;
      margin-bottom: 15px;
    }
  }
}