.c-extra {
  display: flex;
  flex-wrap: wrap;
  //justify-content: center;

  &__item {
    width: 195px;
    text-align: center;
    padding: 15px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    p {
      margin-bottom: 15px;
      min-height: 35px;
    }

    span {
      display: block;
      margin-bottom: 10px;
      font-size: 24px;
    }

  }

  &__img {
    height: 165px;
    margin-bottom: 10px;
    position: relative;

  }
}

@media screen and (max-width: 1200px) {
  .c-extra {

    &__item {
      width: calc(20% - 5px);
      margin: 0 2.5px 5px;
      padding: 0;
    }
  }
}

@media screen and (max-width: 992px) {
  .c-extra {
    &__img {
      height: 130px;
    }
  }
}

@media screen and (max-width: 768px) {
  .c-extra {
    &__img {
      height: 100px;
    }

    &__item {
      width: calc(25% - 4px);
      margin: 0 2px 4px;
      min-height: 220px;
      p{
        padding-left: 5px;
        text-align: left;
        word-wrap: break-word;
        font-size: 12px;
      }

      span{
        text-align: center;
        font-size: 14px;
      }

      .c-button{
        font-size: 14px;
      }
    }
  }
}