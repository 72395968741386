//ВЫПАДАЮЩИЙ СПИСОК
.c-dropdown {
  position: relative;

  &:hover {
    background: white;
  }

  &--height-primary {
    height: 65px;
    line-height: 65px;
  }

  &--px-primary {
    padding: 0 25px;

  }

  &__title {
    padding: 0 18px;
    position: relative;
    height: 100%;
    cursor: pointer;
    background: transparent;
    transition: all ease .3s;
    &-arrow {
      display: flex;
      margin-left: 4px;
    }

  }

  &__list {
    position: absolute;
    width: 300px;
    z-index: 2;
  }

  &.stock {
    background: #D25C59;
    border-bottom: 5px solid #C4322E;
    &:hover {
      border-bottom: 5px solid black;
    }
  }
}
