.c-messangers{
  line-height: 20px;
  &__item{
    margin-left: 10px;
    &:first-child{
      margin-left: 0;
    }
  }
}


