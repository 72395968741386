.s-flower-nav {
  height: 65px;
  box-shadow: 0px 10px 40px rgba(36, 25, 28, 0.04);
  transition: all ease .3s;
  margin-bottom: 20px;


  .container{
    .c-dropdown-menu{
      .c-dropdown:hover{
        .c-dropdown__title{
          background: white;
          a{
            color: black;
          }
        }
        .c-dropdown__list{
          display: block;
        }
      }
    }

  }

  .c-dropdown {
    .active {
      color: #404040;
      background: white;
    }

    &__list {
      width: 300px;
      padding: 20px;
      column-count: 2;
      height: auto;
      line-height: 1.7;
      display: none;
      z-index: 10;

      li {
        padding: 8px 0;
        border-bottom: 0.5px solid #EFE8E8;
        page-break-inside: avoid;
        break-inside: avoid;
      }
    }

    &:hover{
      background: transparent;
    }

    &__title {
      a {
        height: 65px;
        display: block;
        color: white;
      }

    }

  }

  &__mobile {
    display: none;
  }


  .c-form-control{
    padding-left: 45px;
  }
}

@media screen and (max-width: 1200px) {
  .s-flower-nav {
    .c-dropdown-menu {
      display: none;
    }

    &__mobile {
      display: flex;
      position: relative;

      .c-dropdown {
        width: 250px;
        &__title {
          font-family: Roboto;
          font-size: 18px;
        }

        &__list {
          width: 100%;
          column-count: 1;
          span {
            color: #7E7B7B;
            display: block;
            margin-bottom: 5px;
          }

          a {
            font-size: 18px;
            font-weight: normal;
          }
        }
      }
    }
  }

}


@media screen and (max-width: 768px){
  .s-flower-nav{
    margin-bottom: 25px;
  }

}