
.time-range {
  z-index: 2;
  max-width: 280px;
  width: 100%;
  text-align: center;
  padding: 10px 0 25px 0;
  background: white;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, 0);
  -webkit-border-radius: 8px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0,0,0,.25);
  display: none;
  p {
    font-size: 14px;
    color: black;
    border-bottom: 1px solid #c2c2c2;
    margin-bottom: 25px;
    padding: 0 0 10px 0;
  }
  // Обновление слайдера
  .ui-slider-horizontal {
    height: 8px;
    background: #e6e6e6;
    border: 1px solid #c2c2c2;
    box-shadow: 0 1px 0 #fff, 0 1px 0 #fff inset;
    clear: both;
    margin: 8px 0;
    border-radius: 6px;
  }
  .ui-slider {
    position: relative;
    text-align: left;
  }
  .ui-slider-horizontal .ui-slider-range {
    top: -1px;
    height: 100%;
  }
  .ui-slider .ui-slider-range {
    position: absolute;
    z-index: 1;
    height: 8px;
    font-size: .7em;
    display: block;
    border-radius: 6px;
    background-size: 100%;
    background: #55612E;

  }
  .ui-slider .ui-slider-handle {
    border-radius: 50%;
    background: white;
    background-size: 100%;
    width: 22px;
    height: 22px;
    box-shadow: 0 2px 3px -1px rgba(0, 0, 0, 0.6);
    transition: box-shadow .3s;
  }
  .ui-slider .ui-slider-handle {
    position: absolute;
    z-index: 2;
    width: 22px;
    height: 22px;
    cursor: default;
    border: none;
    cursor: pointer;
    box-shadow: 0 2px 3px -1px rgba(0,0,0,.6);
  }
  .ui-slider .ui-slider-handle:after {
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    top: 50%;
    margin-top: -4px;
    left: 50%;
    margin-left: -4px;
    background: #525151;
  }
  .ui-slider-horizontal .ui-slider-handle {
    top: -.5em;
    margin-left: -.6em;
  }
  .ui-slider a:focus {
    outline: none;
  }

  .slider-range-time {
    width: 90%;
    margin: 0 auto;
  }

  .c-close {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 2;
  }
}


