//КАРТА
.c-card {
  width: calc(100% / 3);
  transition: all ease .3s;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;


  &__tags{
    position: absolute;
    right: 10px;
    top: 70px;
    ul{
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    li{
      padding: 3px;
      background: #EB5757;
      text-align: center;
      width: max-content;
      margin-bottom: 5px;
      min-width: 50px;

      font-size: 10px;


      text-transform: uppercase;

      color: #FFFFFF;
    }
  }





  .product__price-sale{
    font-size: 16px;
    text-decoration: line-through;
  }

  &__height{
    display: flex;
    align-items: center;
  }

  &__like {
    cursor: pointer;
    z-index: 2;
    height: 28px;
  }

  &__to-product {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    cursor: pointer;
  }

  //ВЕРХНЯЯ ЧАСТЬ КАРТЫ
  &__top {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    font-size: 9px;
    min-height: 394px;
    position: relative;
    overflow: hidden;

    .h-image-cover {
      z-index: -1;
      object-fit: contain;
      object-position: top;
    }
  }

  //ИНФОРМАЦИЯ КАРТЫ
  &__info {
    display: none;
    min-height: 74px;
    padding: 10px;
    font-size: 13px;
    width: calc(100% - 40px);
    position: absolute;
    bottom: 20px;

    p{
      position: relative;
      z-index: 1;
    }

    span{
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0.65;
      z-index: -1;
    }
  }

  //РАЗМЕР БУКЕТА
  &__size {
    display: flex;
    width: 60px;
    height: 45px;
    font-size: 12px;
    justify-content: flex-end;
    padding: 5px;
    position: relative;
    left: 10px;
    bottom: 10px;

    //СТИЛИ СТРЕЛОЧЕК
    img {
      display: initial;
      margin-right: 5px;
    }

  }

  .c-button {
    z-index: 2;
  }

  //ШИРИНА БУКЕТА
  &__width {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 5px;
    position: relative;
    top: 20px;
  }

  .h-image-cover{
    transition: all ease 3s;
    transform: scale(1);
  }

  &:hover {
    box-shadow: 0px 2px 70px rgba(0, 0, 0, 0.3);
    .c-card__info {
      display: block;
    }

    .h-image-cover{
      transform: scale(1.3);
    }
  }

  //НИЖНЯЯ ЧАСТЬ КАРТОЧКИ
  &__bottom {
    padding: 20px;
    min-height: 165px;
    text-align: center;
    z-index: 2;
    position: relative;
  }

  &__title {
    font-size: 14px;
    margin-bottom: 15px;
  }

  //НАЗВАНИЕ БУКЕТА
  &__price {
    font-size: 24px;
    margin-bottom: 25px;
  }

}

@media screen and (max-width: 992px) {
  //КАРТА
  .c-card {
    width: 50%;
  }

}

@media screen and (max-width: 768px) {
  //КАРТА
  .c-card {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;


    &__tags{
      right: 0;
      top: 55px;
    }

    //ИНФОРМАЦИЯ КАРТЫ

    &:hover {
      .c-card__info {
        display: none;
      }
    }

    //КОНТЕЙНЕР ДЛЯ КАРТИНКИ КАРТЫ
    &__img {

      //КАРТИНКА КАРТЫ
      img {
        width: 200px;
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
      }
    }

    //ВЕРХНЯЯ ЧАСТЬ КАРТЫ
    &__top {
      min-height: 180px;
      padding: 10px;
      overflow: hidden;
    }

    //ЦЕНА
    &__price {
      margin-bottom: 10px;
    }

    //НИЖНЯЯ ЧАСТЬ КАРТЫ
    &__bottom {
      min-height: auto;
    }

    .c-button{
      width: 90%;
    }
  }
}