//ЗВОНОК
.c-bell {
  line-height: 2;

  &__title{
    line-height: 1.3;
  }

  //ТЕЛЕФОННЫЙ НОМЕР
  &__number {
    font-size: 18px;
  }
}