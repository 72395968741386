.s-header-top{
  border-bottom: 0.5px solid #E5E5E5;

  .stock{
    border: 1px dashed #C4322E;
    background: rgba(#C4322E, 0.1);
    border-radius: 4px;
    padding: 4px;
    &:hover{
      color: #404040;
    }
  }

  .c-messangers{
    margin-right: 45px;
  }

  .c-currency{
    border-right: 0.5px solid #E5E5E5;
    border-left: none;


  }

  .c-like{
    border-right: 0.5px solid #E5E5E5;
    border-left: 0.5px solid #E5E5E5;
  }
}

@media screen and (max-width: 1200px){
  .s-header-top{
    display: none;
  }
}