//КОНТАКТЫ
.s-contacts {
  margin-bottom: 120px;

  //БЛОК С АДРЕССАМИ
  &__addresses {
    width: 470px;
    min-height: 367px;
    margin-right: 30px;
    flex-wrap: wrap;

    &-part{
      margin-bottom: 20px;
      padding-left: 30px;
      position: relative;
      svg{
        position: absolute;
        left: 0;
      }
    }

    //ОДИН АДРЕС
    &-item {
      width: calc(50% - 7.5px);
      margin-bottom: 10px;

      //ТЕКСТ С ИНФОРМАЦИЕЙ
    }

    //КАРТИНКА
    &-img {
      position: relative;
      height: 160px;
      margin-bottom: 20px;
    }

  }

  //КАРТА
  &__map {
    width: calc(100% - 500px);
    min-height: 367px;

    #map {
      height: 367px;
    }
  }
}

@media screen and (max-width: 1200px) {
  //КОНТАКТЫ
  .s-contacts {
    flex-direction: column;

    //БЛОК С АДРЕССАМИ
    &__addresses {
      margin-right: 0px;
      margin-bottom: 60px;
    }

    //КАРТА
    &__map {
      width: 100%;
    }
  }

}

@media screen and (max-width: 576px) {
  .s-contacts {

    //БЛОК С АДРЕССАМИ
    &__addresses {
      width: 100%;
      margin-bottom: 60px !important;

      &-img
      img {
        width: 100%;
      }
    }

    &__map {
      #map {
        height: 245px;
      }
    }
  }
}
