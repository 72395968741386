
.s-text-block {
  margin-bottom: 120px;
  &__content {
    border-radius: 6px;
    font-weight: 300;
    font-size: 16px;
    &-container {
      max-width: 854px;
      margin: 0 auto;
      padding: 60px 25px;
      p {
        margin-bottom: 15px;
        &:last-child{
          margin-bottom: 0;
        }
      }
    }
  }
}


@media screen and (max-width: 768px){
  .s-text-block{
    margin-bottom: 60px;
    &__content-container{
      padding: 25px;
    }
  }


}

