//РЕЗУЛЬТАТ ЗАКАЗА
.s-order-result {
  background: white;
  box-shadow: 0px 2px 40px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  max-width: 650px;
  width: 100%;
  margin: 0 auto 50px;
  padding: 40px 150px;

  p {
    font-weight: 300;
    font-size: 14px;
  }

  //ВАШ ЗАКАЗ ОФОРМЛЕН
  &__verification {
    padding: 20px;
    border-radius: 4px;
    margin-bottom: 20px;

    h3 {
      margin-bottom: 15px;
    }
  }

  //ВОЙДИТЕ В ЛИЧНЫЙ КАБИНЕТ
  &__personal-area {
    max-width: 300px;
    margin: 0 auto 15px;
  }

  .c-button {
    margin-bottom: 20px;
  }

  //ВЕРНУТЬСЯ НА ГЛАВНУЮ
  &__return {
    font-size: 16px;
    padding-right: 35px;
    position: relative;
    width: max-content;
    margin: 0 auto;

    &:hover {
      &:after {
        right: -15px;
      }
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 30px;
      height: 7px;
      background: url(img/sections/s-order-result/arrow.svg);
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      transition: all ease .3s;
    }
  }

}

@media screen and (max-width: 768px) {
  //РЕЗУЛЬТАТ ЗАКАЗА
  .s-order-result {
    padding: 40px 20px;
  }
}