.s-header-bottom {
  height: 105px;
  line-height: 105px;
  overflow: hidden;



  .c-logo {
    width: 150px;
    display: block;
    margin-right: 110px;

    img {
      height: 100%;
    }
  }

  .c-bell {
    margin-right: 30px;
  }

  .c-list {
    margin-right: 20px;
  }

  .c-search {
    margin-right: 20px;


  }

}



.c-basket {
  position: relative;
  cursor: pointer;

  &__icon {
    margin-right: 5px;
  }
  &__count {
    top: -17px;
    position: absolute;
    left: 4px;
    width: 25px;
    display: flex;
    justify-content: center;
  }
}

@media screen and (max-width: 1200px) {
  .s-header-bottom {
    justify-content: space-between;
    height: 85px;
    line-height: 85px;

    .c-logo {
      width: 100px;
      height: auto;
      margin-right: 0;
    }

    .c-bell {
      display: none;

    }

    .c-list {
      display: none;
    }

    .c-basket {
      position: relative;

      &__icon {
        margin-right: 5px;
      }
    }
  }
}

