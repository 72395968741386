//БЛОК С ИСТОРИЕЙ
.c-history {

  //ЗАГОЛОВОК
  &__title {
    font-size: 22px;
    margin-bottom: 30px;
  }

  &__row {
    height: 150px;
    font-size: 18px;
    display: flex;
    align-items: center;
  }

  //МАРКЕР
  &__mark {
    width: 5%;
    position: relative;
    cursor: pointer;

    &-prompt {
      position: absolute;
      width: 284px;
      height: 80px;
      border-radius: 4px;
      font-size: 18px;
      top: 65px;
      left: -10px;
      padding: 15px;
      transition: all ease .5s;
      display: none;
    }

    &:hover {
      .c-history__mark-prompt {
        display: block;
      }
    }

    &:before {
      content: '';
      display: block;
      width: 14px;
      height: 14px;
      background: black;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
    }
  }

  //НОМЕР ЗАКАЗА
  &__number {
    width: 20%;
  }

  //ФОТО ОТЧЕТ
  &__img {
    width: 20%;
    display: flex;
    align-items: center;

    //КОНТЕЙНЕР ДЛЯ КАРТИНКИ
    &-container {
      min-width: 50px;
      width: 50px;
      height: 50px;
      margin-right: 8px;
    }

    //ЕЩЁ ФОТОГРАФИИ
    &-more {
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 0.02em;
    }
  }

  //ДАТА
  &__date {
    width: 20%;
  }

  //ЦЕНА
  &__price {
    width: 20%;
  }

  //КНОПКА
  &__button {
    width: 15%;
    height: 35px;
    font-size: 16px;
    line-height: 35px;
    text-align: center;
    letter-spacing: 0.06em;
  }

  //НАВИГАЦИЯ
  &__nav {

    //ЗАГОЛОВОК КОЛОНКИ
    &-item {
      font-size: 22px;
      padding-bottom: 20px;
      &:nth-child(1) {
        width: 5%;
      }

      &:nth-child(2) {
        width: 20%;
      }

      &:nth-child(3) {
        width: 20%;
      }

      &:nth-child(4) {
        width: 20%;
        font-weight: 300;
      }

      &:nth-child(5) {
        width: 20%;
        font-weight: 300;
      }

      &:nth-child(6) {
        width: 15%;
      }
    }
  }

  //СТРОКА
  &__item {
    display: flex;
    align-items: center;
    height: 120px;
    //border: 1px solid black;
    &:nth-child(1) {
      width: 5%;
    }

    &:nth-child(2) {
      width: 20%;
    }

    &:nth-child(3) {
      width: 20%;
    }

    &:nth-child(4) {
      width: 20%;
      font-weight: 300;
    }

    &:nth-child(5) {
      width: 20%;
      font-weight: 300;
    }

    &:nth-child(6) {
      width: 15%;
      height: fit-content;
      justify-content: center;
    }
  }
}



@media screen and (max-width: 1200px) {
  //БЛОК С ИСТОРИЕЙ
  .c-history {
    &__nav {
      display: none;
    }

    //СТРОКА
    &__row {
      height: fit-content;
    }

    //ПУНКТ
    &__item {
      height: auto;
      width: 100% !important;
      min-height: 30px;
    }

    &__mark {
      grid-column: 1/2;
      gtid-row: 1/2;
      &:before {
        left: 25px;
      }
    }

    &__number {
      grid-column: 2/3;
      gtid-row: 1/2
    }

    &__date {
      grid-column: 3/4;
      gtid-row: 1/2
    }

    //ФОТО ОТЧЕТ
    &__img {
      flex-direction: column;
      align-items: baseline;
      margin-bottom: 10px;
      grid-column: 1/3;
      grid-row: 2/4;

      //КОНТЕЙНЕР ДЛЯ КАРТИНКИ
      &-container {
        min-width: 100px;
        width: 100px;
        height: 100px;
        margin-right: 0px;
        margin-bottom: 15px;
      }
    }

    //КНОПКА
    &__button {
      height: 35px;
      font-size: 16px;
      line-height: 35px;
      text-align: center;
      letter-spacing: 0.06em;
      align-self: end;
    }

    //СТРОКА
    &__row {
      display: grid;
      grid-template-columns: 30% 25% 45%;
      align-items: baseline;
      justify-items: stretch;
      margin-bottom: 60px;

    }

  }

}

