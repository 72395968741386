//ФЛОРИСТЫ
.s-florists {
  margin-bottom: 120px;
  &-wrap{
    min-height: 240px;
  }


  //ФОТОГРАФИЯ ФЛОРИСТА
  &__photo {
    width: 350px;
    height: 350px;
    position: relative;
  }

  //ИНФОРМАЦИЯ О ФЛОРИСТЕ
  &__info {
    width: calc(100% - 380px);

    //ИМЯ ФЛОРИСТА
    strong {
      font-size: 18px;
      display: block;
      margin-bottom: 15px;
      font-weight: normal;
    }

    p {
      font-size: 16px;
      margin-bottom: 10px;
    }
  }

  //ДИПЛОМЫ ФЛОРИСТА
  &__diploms {
    margin-bottom: 60px;

    //ДИПЛОМ ФЛОРИСТА
    &-item {
      width: 50px;
      height: 70px;
      margin-right: 15px;
      position: relative;
      &:last-child {
        margin-right: 0;
      }
    }

  }


  //СПИСОК ФЛОРИСТОВ
  &__list{

  }


  //ФЛОРИСТ
  &__item{
    margin-right: 15px;
    &:last-child{
      margin-right: 0;
    }
  }


  //МАЛЕНЬКОЕ ФОТО ФЛОРИСТА
  &__small-photo{
    width: 85px;
    height: 85px;
    margin-bottom: 10px;
    position: relative;
    cursor: pointer;
  }

  //ИМЯ ФЛОРИСТА
  &__name{
    font-weight: 300;
    color: #404040;

  }

}


@media screen and (max-width: 768px){


  //ФЛОРИСТЫ
  .s-florists{
    flex-direction: column;


    //ФОТОГРАФИЯ ФЛОРИСТА
    &__photo{
      width: 275px;
      height: 275px;
      margin-bottom: 25px;
    }


    //ИНФОРМАЦИЯ О ФЛОРИСТЕ
    &__info{
      width: 100%;
    }


    //ДИПЛОМЫ ФЛОРИСТА
    &__diploms{


      //ДИПЛОМ ФЛОРИСТА
      &__item{
        width: 110px;
        height: 150px;
      }
    }


    //МАЛЕНЬКОЕ ФОТО ФЛОРИСТА
    &__small-photo{
      width: 100px;
      height: 100px;
    }

  }


}