.c-list{
  &__item{
    line-height: 1.8;
  }
  &__text{
    line-height: 1;
  }

  &__icon{
    margin-right: 5px;
    svg{
      width: 22px;
      height: 22px;
    }
  }
}

