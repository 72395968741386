.s-reviews {
  margin-bottom: 175px;

  &__name {
    font-size: 18px;
    margin-bottom: 5px;
  }

  &__text {
    font-weight: 300;
  }

  &__item {
    position: relative;
    overflow: hidden;

  }

  &__card {
    width: 332px;
    padding: 60px 25px 25px;
    margin: 0 auto;
  }

  &__slider{
    opacity: 0;
  }

  &__stars {
    display: flex;
    margin-bottom: 10px;
    position: absolute;
    top: 20px;
    img {
      margin-right: 5px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.s-reviews__slider .slick-next {
  right: 0px;
}

.s-reviews__slider .slick-prev {
  left: 0px;
}

.s-reviews__slider .slick-next:before {
  right: 0px;
}

.s-reviews__slider .slick-prev:before {
  left: 0px;
}

//АКТИВНАЯ КАРТОЧКА В СЛАЙДЕРЕ
.s-reviews__slider .slick-track {
  display: flex;
  .slick-current + .slick-slide {

    &__stars {
      svg {
        width: 30px;
        height: 30px;
      }
    }
  }
}

.s-reviews__slider .slick-track {
  align-items: center;
  display: flex;
}

.s-reviews__slider .slick-dots {
  bottom: -55px;
}

//@media screen and (max-width: 1200px) {
//  .slick-current {
//    .s-reviews {
//
//      &__stars {
//        svg {
//          width: 30px;
//          height: 30px;
//        }
//      }
//    }
//  }
//
//}

@media screen and (max-width: 768px) {

  .s-reviews {
    margin-bottom: 60px;
  }

  .s-reviews__card {
    width: calc(100% - 80px);
  }

}