//c-settings-btn

.c-settings-btn {
  display: flex;
  height: 37px;
  line-height: 37px;
  cursor: pointer;
  margin-bottom: 7px;
  position: relative;
  &__ic {
    width: 35px;
    text-align: center;
  }

  &--2 {
    width: 37px;
    justify-content: center;
    margin-right: 5px;
  }

}