
.c-process {
  margin-bottom: 60px;
  position: relative;
  &:before {
    content: '';
    width: calc(100% - 125px);
    left: 50%;
    transform: translateX(-50%);
    height: 1px;
    display: block;
    position: absolute;
    top: 25px;

  }
  &__item {
    max-width: 150px;
    width: 100%;
    text-align: center;

    &-done {
      .c-process__num {
        color: white;
        border: none;
      }

    }
  }

  &__num {
    width: 50px;
    height: 50px;
    line-height: 50px;
    border-radius: 50%;
    color: black;
    margin: 0 auto 10px;
    background: white;
    position: relative;
    z-index: 1;
  }
}

@media screen and (max-width: 768px) {
  .c-process__item {
    p {
      display: none;
    }
  }

}