//КНОПКА
.c-button {
  cursor: pointer;
  display: block;
  max-width: 250px;
  width: 100%;
  height: 40px;
  line-height: 40px;
  text-align: center;
  margin: 0 auto;
  background: transparent;

  //ПЕРВИЧНЫЙ ТИП КНОПКИ
  &--primary {
    &:hover {
      color: white;
      text-decoration: none;
    }
  }

  &--primary-type{
    color: white;
    text-decoration: none;
  }

  //ПЕРВИЧНАЯ ВЫСОТА
  &--primary-height {
    height: 50px;
    line-height: 50px;
  }

  //ПЕРВИЧНАЯ ШИРИНА
  &--primary-width {
    max-width: 280px;
  }



  .old-price-value{
    font-size: 13px;
    text-decoration: line-through;
    margin-right: 3px;
  }

}


@media screen and (max-width: 992px){
  //КНОПКА
  .c-button{
    font-size: 14px;
  }

}