//ЗАДАТЬ ВОПРОС
.c-ask {
  color: white;
  position: fixed;
  right: 15px;
  bottom: 15px;
  z-index: 10;
  display: none;
  right: 50px;

  //КНОПКА
  &__button {
    cursor: pointer;
    width: 187px;
    height: 50px;
    line-height: 50px;
    display: flex;
    justify-content: center;

    //ИКОНКА
    img {
      margin-right: 10px;
    }

    p{
      color: white;
    }


  }

  //СЧЕТЧИК
  &__count {
    width: 25px;
    height: 25px;
    line-height: 25px;
    border-radius: 50%;
    position: absolute;
    left: -35px;
    top: 50%;
    transform: translateY(-50%);
  }

  //БАЗОВОЕ СООБЩЕНИЕ
  &__info {
    width: 300px;
    height: 80px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.08);
    border-radius: 2px;
    position: absolute;

    top: -95px;
    left: -110px;
    padding: 18px 23px;

    //ЗАКРЫВАЮЩАЯ СООБЩЕНИЕ КНОПКА
    &__close {
      cursor: pointer;
      position: absolute;
      right: 10px;
      top: 5px;
    }


    &:before {
      content: "";
      width: 15px;
      height: 15px;
      background: #fff;
      position: absolute;
      bottom: -8px;
      transform: rotate(45deg);
      right: 20px;
    }
  }

}

@media screen and (max-width: 768px){
  .c-ask{
    right: 5px;
    bottom: 5px;
    &__button{
      width: 140px;
      height: 40px;
      line-height: 40px;
      font-size: 12px;
    }

    &__info{
      display: none;
    }

    &__count{
      display: none;
    }
  }

}