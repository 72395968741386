//ИЗОБРАЖЕНИЯ
.h-image-cover {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
}

//ДИСПЛЕЙ БЛОК

.h-d-b {
  display: block;
}


.h-ff-primary{
  font-family: 'Merriweather', serif;
}



//ФЛЕКСЫ
.h-d-flex {
  display: flex;
}

.h-jc-sb {
  justify-content: space-between;
}

.h-jc-fe {
  justify-content: flex-end;
}

.h-jc-c {
  justify-content: center;
}

.h-ai-c {
  align-items: center;
}

.h-fd-c {
  flex-direction: column;
}

//РАЗМЕР ШРИФТА
.h-fsz-1 {
  font-size: 12px;
}

.h-fsz-2 {
  font-size: 14px;
}

.h-fsz-3 {
  font-size: 16px;
}

.h-fsz-4 {
  font-size: 18px;
}

//НАСЫЩЕННОСТЬ ШРИФТА
.h-fw-300 {
  font-weight: 300;
}

.h-fw-normal {
  font-weight: normal;
}

.h-fw-bold {
  font-weight: bold;
}




//ОТСТУПЫ
.h-mb-0 {
  margin-bottom: 0 !important;
}

//ВЫРАВНИВАНИЕ ТЕКСТА
.h-ta-c {
  text-align: center;
}

.h-ta-l{
  text-align: left;
}

//СТИЛЬ ТЕКСТА
.h-td-u {
  text-decoration: underline;
}


//ПОЗИЦИЯ
.h-p-r{
  position: relative;
}




