.c-town {
  display: flex;
  position: relative;
  padding-right: 10px;

  &__title {
    cursor: pointer;
    width: auto;
    height: 45px;
    line-height: 45px;
    font-size: 14px;
    text-decoration-line: underline;
    position: relative;
    padding-left: 35px;
    padding-right: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-align: center;
    &:after {
      content: '';
      width: 24px;
      height: 45px;
      line-height: 45px;
      position: absolute;
      background: url(img/blocks/c-town/baloon.svg) no-repeat center;
      left: 0;
    }
  }

  &__dropdown {
    display: none;
    z-index: 1;

    width: 300px;

    background: white;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);

    padding: 20px;

    position: absolute;
    top: 46px;
    left: 50%;
    transform: translateX(-50%);

    &-search {
      margin-bottom: 30px;
      &:before {
        content: '';
        display: block;

        width: 20px;
        height: 50px;

        background: url(img/blocks/c-town/search.svg) no-repeat center;

        right: 30px;

        position: absolute;
      }

    }

    ul {
      column-count: 2;
    }

    li {
      display: block;
      page-break-inside: avoid;
      break-inside: avoid;

      padding: 8px 0;

    }
  }
}